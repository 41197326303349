.tabs-container {
    width: 100%;
}

.nav-tabs {
    border-bottom: 1px solid #ddd;
}

.nav-item {
    margin-bottom: -1px;
}

.nav-tab {
    background-color: #f8f9fa;
    border: 1px solid transparent;
    border-radius: 0;
    color: #007bff;
    padding: 10px;
}

.nav-tab.active {
    color: #ffffff;
    background-color: #000000;
    border-color: #ddd #ddd #fff;
}

.tab-content {
    padding: 15px;
    border: 1px solid #ddd;
    border-top: none;
    background-color: #fff;
}
.tab-content{
    margin-top: -14px;
}

.books-container {
    display: flex;
    gap: 20px;
  }
  
  .book-card {
    min-width: 200px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .tabs-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .tabs-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 10px;
  }
  
  .tab-button {
    padding: 10px 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    transition: all 0.3s ease;
  }
  
  .tab-button.active {
    color: #007bff;
    border-bottom: 2px solid #007bff;
  }
  
  .tab-content {
    padding: 20px 0;
  }
  
  .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .scroll-section {
    margin-bottom: 30px;
  }
  
  .scroll-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .scroll-content {
    display: flex;
    gap: 20px;
    padding-bottom: 15px;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }