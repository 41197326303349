.view-books-container {
    padding: 20px;
    overflow: hidden;
  }
  
  .horizontal-scroll-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 20px 0;
  }
  
  .horizontal-scroll-wrapper::-webkit-scrollbar {
    display: none;
  }

  .vb-container {
    padding: 15px;
    overflow: hidden;
  }
  
  .vb-scroll-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 10px 0;
  }
  
  .vb-scroll-wrapper::-webkit-scrollbar {
    display: none;
  }
  
  .vb-card-wrapper {
    flex: 0 0 auto;
    width: 250px;
    margin-right: 15px;
    scroll-snap-align: start;
  }
  
  .vb-book-card {
    height: 100%;
    transition: transform 0.3s ease;
    box-shadow: 0 px 12px rgba(0, 0, 0, 0.1);
  }
  
  .vb-book-card:hover {
    transform: translateY(-3px);
  }
  
  .vb-image-wrapper {
    position: relative;
    height: 150px;
    overflow: hidden;
  }
  
  .vb-image-wrapper img {
    height: 100%;
    object-fit: cover;
  }
  
  .vb-badge {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 0.7rem;
    padding: 2px 5px;
  }
  
  .vb-book-card .card-body {
    padding: 10px;
  }
  
  .vb-book-card .card-title {
    font-size: 1rem;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .vb-book-info {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  
  .vb-book-info p {
    margin: 0;
    line-height: 1.3;
  }
  
  .vb-price-info {
    margin-top: 5px;
    font-size: 0.8rem;
  }
  
  .vb-selling-price {
    color: #28a745;
    font-weight: bold;
  }
  
  .vb-post-date {
    font-size: 0.7rem;
    color: #6c757d;
  }
  
  .vb-button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .vb-button-group .btn {
    font-size: 0.8rem;
    padding: 2px 8px;
  }
  
  .vb-book-card .card-footer {
    font-size: 0.7rem;
    color: #6c757d;
    padding: 5px 10px;
  }
  
  .vb-load-more {
    text-align: center;
    margin-top: 15px;
  }

  .vb-Category-price {
    color: #1c77f7;
    font-weight: bold;
  }
  .vb-book-title{
    color: rgb(0, 0, 0);
    font-stretch: extra-condensed;
  }

  .searchBar{
    width: 100%;
    max-width: 340px;
  }