.vc-container {
  padding: 20px;
}

.vc-scroll-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.vc-card-wrapper {
  width: 300px;
}

.vc-course-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.vc-image-wrapper {
  position: relative;
  height: 0px;
  overflow: hidden;
}

.vc-course-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vc-badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.vc-course-info p {
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.vc-price-info {
  margin-top: 10px;
}

.vc-selling-price {
  font-weight: bold;
  font-size: 1.2rem;
}

.vc-discount {
  color: #28a745;
  font-size: 0.9rem;
}

.vc-post-date {
  font-size: 0.8rem;
  color: #6c757d;
}

.vc-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.load-more {
  text-align: center;
  margin-top: 20px;
}