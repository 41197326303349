/* FeatureShowcase.css */
.hc-features-container {
    background-color: #f8f9fa;
    padding: 80px 0;
    overflow: hidden;
  }
  
  .hc-features-content {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 0 20px; */
  }
  
  .hc-features-title {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    /* margin-bottom: 40px; */
    color: #1a1a1a;
  }
  
  .hc-features-grid {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .hc-feature-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    /* transform: translateY(100px);
    transition: opacity 0.8s ease, transform 0.8s ease; */
    transform: translateY(50px);
    transition: opacity 0.9s ease, transform 0.9s ease;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
  }
  
  .hc-feature-block:nth-child(even) {
    flex-direction: row-reverse;
    
  }
  
  .hc-feature-visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .hc-feature-content {
    flex: 1;
    max-width: 500px;
    transform: translateY(0);
  }
  
  .hc-feature-icon {
    /* font-size: 3rem;
    margin-bottom: 20px; */
    font-size: 5rem;
    margin-bottom: 15px;
    display: inline-block;
    background-color: #f0f4f8;
    padding: 15px;
    border-radius: 50%;
  }
  
  .hc-feature-heading {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: #1a1a1a;
  }
  
  .hc-feature-description {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #1a1a1a;
  }
  
  .hc-feature-component {
    flex: 1;
    max-width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .hc-feature-component:hover {
    transform: translateY(-10px);
  }
  


  .hc-feature-block,
  .hc-feature-block:nth-child(even) {
    flex-direction: column;
    text-align: center;
    gap: 40px;
  }

  .hc-feature-content,
  .hc-feature-component {
    max-width: 100%;
  }

  .hc-features-title {
    font-size: 2rem;
  }

  .hc-feature-heading {
    font-size: 1.5rem;
  }

  .hc-feature-description {
    font-size: 1rem;
  }
  @media (min-width: 768px) {
    .hc-features-container {
      padding: 80px 0;
    }
  
    .hc-feature-block {
      padding: 40px 30px;
    }
  
    .hc-features-title {
      font-size: 2.2rem;
      margin-bottom: 50px;
    }
  
    .hc-feature-heading {
      font-size: 1.6rem;
    }
  
    .hc-feature-description {
      font-size: 1.1rem;
    }
  }



/* pdf model */

.modal-dialog.modal-fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  max-width: none;
}

.modal-dialog.modal-fullscreen .modal-content {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
}

.modal-dialog.modal-fullscreen .modal-body {
  height: calc(100% - 56px); /* Adjust this value based on your modal header height */
  padding: 0;
  overflow: hidden;
}

.modal-dialog.modal-fullscreen .modal-body object {
  width: 100%;
  height: 100%;
}