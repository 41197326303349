.rotating-text {
    display: inline-block;
    width: 50px;
    font-size: 16px; /* Adjust size as needed */
    font-weight: bold;
    margin-left: 1px; /* Space between logo and text */
    overflow: hidden;
    height: 1.3em; /* Adjust based on font size */
    position: relative;
    white-space: nowrap;
    color: azure;
}

.rotating-text .text {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 1.4em; /* Align text vertically */
    animation: rotateText 3s infinite; /* Total duration for one full rotation cycle */
    color: azure;
}

@keyframes rotateText {
    0% {
        transform: translateY(0);
        opacity: 1; /* Fully visible at start */
    }
    20% {
        transform: translateY(0);
        opacity: 1; /* Fully visible */
    }
    25% {
        transform: translateY(-100%);
        opacity: 0; 
    }
    45% {
        transform: translateY(-100%);
        opacity: 0; 
    }
    50% {
        transform: translateY(-200%);
        opacity: 1; /* Fully visible */
    }
    70% {
        transform: translateY(-200%);
        opacity: 1; /* Fully visible */
    }
    75% {
        transform: translateY(-300%);
        opacity: 0; 
    }
    95% {
        transform: translateY(-300%);
        opacity: 0; /* Fully transparent */
    }
    100% {
        transform: translateY(0);
        opacity: 1; /* Fully visible */
    }
}

